import { PerformanceTrackingKeys } from "core-ui/client/react/core/constants/constants";
import AccessibilityUtil from "core-ui/client/src/app/core/util/AccessibilityUtil";
import { isMobileDevice } from "core-ui/client/src/app/core/util/Devices";
import { AMPLITUDE_EVENTS } from "core-ui/client/src/app/core/amplitude";
import ExternalLogger from "core-ui/client/src/app/ExternalLogger";
import CustomEvent from "custom-event";
import EventBusEvents from "../../app/login/events/EventBusEvents";
import _has from "lodash/has";
import _isUndefined from "lodash/isUndefined";
import _mapValues from "lodash/mapValues";
import _some from "lodash/some";

import localBuildConfig from "../../../../build/buildConfig.json";
import redwoodAuthenticateToken from "../../app/login/services/redwoodAuthenticateToken";
import loadFtd from "../../app/login/components/loginForm/helpers/loadFtd";
import AccountOpenEvents from "../accountOpen/events/AccountOpenEvents";
import FingerprintUtils from "../utils/FingerprintUtils";

import CoreEvents from "./events/CoreEvents";

const hideLoginBeforeAuthPatterns = [
    /\/accessDeniedError/,
    /\/account-recovery/,
    /\/account-selection/,
    /\/account-setup/,
    /\/accountOpenDetails/,
    /\/accountOpenPersonalDetails/,
    /\/articles/,
    /\/change-personal-contact/,
    /\/choose-primaryplan/,
    /\/contact-update/,
    /\/csrfAccessDeniedError/,
    /\/force-change-password/,
    /\/generic-error/,
    /\/linkable-plans/,
    /\/linked-confirmation/,
    /\/loginHelp/,
    /\/loginHelpOptions/,
    /\/mfa/,
    /\/npdi/,
    /\/oauth/,
    /\/oneIdMfaActivationCode/,
    /\/oneIdMfaEnterCode/,
    /\/openAnAccount/,
    /\/otp-verify/,
    /\/idProofWithPin/,
    /\/idProofError/,
    /\/idProofQueries/,
    /\/indId-selection/,
    /\/mailPin/,
    /\/profile/,
    /\/register/,
    /\/register-info/,
    /\/register\/group/,
    /\/register\/pin/,
    /\/registration-summary/,
    /\/versions/,
    /\/eConsent\/change-Comm-Pref/,
    /\/re-enroll/,
    /\/paeSSOlanding/,
    /\/ssoSpinner/,
    /\/mobileSSO/,
    /\/inboundSSO/,
    /\/iraTerms/,
    /\/activationCodeDeliveryOptions/,
    /\/verifyCode/,
    /\/resetPasswordActivationCodeDeliveryOptions/
];

const ONE_TRUST_EVENTS = {
    "Preference Center - Confirm": EventBusEvents.ONETRUST_DO_NOT_SELL_SHARE_INFO,
    "Banner - Allow All": EventBusEvents.ONETRUST_ACCEPT_COOKIES,
    "Banner - Close": EventBusEvents.ONETRUST_CLOSE_PROMPT_CLICKED
};

const LoginUIMasterController = function (
    $cookies,
    $http,
    $injector,
    $location,
    $modalStack,
    $q,
    $rootScope,
    $scope,
    $state,
    $timeout,
    $translate,
    $window,
    AccuCodeService,
    AuthenticationService,
    AuthenticationStatusService,
    BasicAuthenticationService,
    DefaultCustomizationRetrService,
    LogoutService,
    MenuNavigationFactory,
    PreLoginAccuRetrievalService,
    ReSetACCUService,
    PreLoginContactInfoService,
    PreLoginBulletinService,
    SetACCUService,
    StateDestinationFactory,
    eventBus,
    redirectService,
    pscPathRetrievalFactory
) {
    /** * Logger. */
    const logger = ExternalLogger.getInstance("LoginUIMasterController");

    const OAUTH_ROUTE = "/oauth";
    const IS_OAUTH = window.location.hash.indexOf(OAUTH_ROUTE) !== -1;
    // when login-ui first loads it loops over all the performance tracking keys and clears them all
    // from the localStorage.
    Array.from(Object.keys(PerformanceTrackingKeys)).forEach((k) => {
        localStorage.removeItem(PerformanceTrackingKeys[k]);
    });
    /**
     * [WCDX-994] [WCDX-6426] Two login forms appear to be rendering from the oauth/login view. Adding this flag
     * to hide the second from entirely from /oauth/login/
     * TODO: Confirm if we even need the second form or can it be removed? Where is it used?
     */
    $scope.isOauth = IS_OAUTH;
    $rootScope.iframeUrl = globalThis.iframeUrl;

    $scope.isReact = String($window.location.href).indexOf("isReact=true") > -1;
    logger.debug(`is react - ["${$scope.isReact}"]`);
    const path = $location.path();
    const deferred = $q.defer();

    $rootScope.ssoLogin = false;

    if (path.indexOf("inbound") !== -1) {
        $rootScope.ssoLogin = true;
    }
    $rootScope.showNonAuthHeader = {
        value: true
    }; //set strictly to true in LoginUI

    $scope.eventBus = eventBus;
    $scope.errorCode = "";
    $scope.authentication = {};
    $scope.authenticationStatus = {};
    $scope.accountSetUp = {};
    $scope.txnAccess = {};
    $scope.registrationFlags = {};
    $scope.urls = {};
    $scope.deepLinkData = {};
    //$scope.styleEnv = getStyleEnv ? getStyleEnv() : styleEnv; /* from stylesheet.js*/
    $scope.styleEnv = __styleEnv__;
    $scope.credentials = {};
    $scope.isBlockingLoaded = false;
    $scope.isLogin = false;
    $scope.blockingMobileDevices = false;
    $scope.showEye = false;
    $scope.timestamp = new Date().getTime();
    $scope.isIE = !(navigator.userAgent.toLowerCase().indexOf("trident/") < 0);
    $scope.enableLoginHelp = "";

    if (!_isUndefined($scope.credentials)) {
        $scope.credentials.username = "";
        $scope.credentials.password = "";
    }

    let authenticated = false;

    const iTunesApps = localBuildConfig.iTunesApps;
    $scope.iTunesAppId =
        iTunesApps !== "undefined"
            ? _has(iTunesApps, $window.accu)
                ? _has(iTunesApps, $window.accu)
                : _has(iTunesApps, "Default")
            : null;

    const multiAuth = localBuildConfig.multiAuth;

    /**
     * View model for the dynamic css in the index
     * @type {{accu: string, product: string, group: string, groupClient: string}}
     */
    $rootScope.css = {
        accu: "not-initialized",
        product: "not-initialized",
        group: "not-initialized",
        groupClient: "not-initialized"
    };

    globalThis.css = {
        accu: "not-initialized",
        product: "not-initialized",
        group: "not-initialized",
        groupClient: "not-initialized"
    };

    $scope.isMobile = null;

    /**
     * Add listener for deeplink flow for decoupled login page to flow into angular deep link flow
     * Event dispatch from new DeliveryOptions page
     * @param {*} e
     */
    window.addEventListener("decoupledDeepLink", function (e) {
        $rootScope.gaId = e.detail.groupId;
        $rootScope.featureName = $scope.featureName ? $scope.featureName : e.detail.deepLinkName;
    });

    $scope.showHidePwd = function (e) {
        const keyCodes = e.charCode === 32 || e.charCode === 13;
        if (e.type === "click" || (e.type === "keypress" && keyCodes)) {
            e.preventDefault();
            $scope.showEye = !$scope.showEye;
            const pwdInput = document.querySelector("#passwordInput");
            pwdInput.type = pwdInput.type === "password" ? "text" : "password";
            const event = new CustomEvent("tooglePwdView");
            document.dispatchEvent(event);
        }
    };
    $scope.handleBannerClick = function (event) {
        const eventBusPayload = {};
        if (event) {
            const target = event.currentTarget;
            eventBusPayload.bannerHref = target.getAttribute("href")?.replace("#", "");

            const bannerText = target.querySelector("h2")?.innerText;
            eventBusPayload.bannerName = bannerText;
        }

        eventBus.dispatch(EventBusEvents.HERO_BANNER_CLICKED, this, eventBusPayload);
        eventBus.dispatchAmplitude({
            event_type: AMPLITUDE_EVENTS.SELECT_BUTTON,
            event_properties: {
                selection: EventBusEvents.HERO_BANNER_CLICKED
            },
            payload: eventBusPayload
        });
    };
    $scope.handlePreloginPodClick = function (event) {
        const eventBusPayload = {};
        if (event) {
            const target = event.currentTarget;
            eventBusPayload.bannerHref = target.getAttribute("href")?.replace("#", "");

            const bannerText = target.querySelector("h3")?.innerText;
            eventBusPayload.bannerName = bannerText;
        }
        eventBus.dispatch(
            EventBusEvents.PRELOGIN_BANNER_LOWER_TILES_CLICKED,
            this,
            eventBusPayload
        );
        eventBus.dispatchAmplitude({
            event_type: AMPLITUDE_EVENTS.SELECT_BUTTON,
            event_properties: {
                selection: EventBusEvents.PRELOGIN_BANNER_LOWER_TILES_CLICKED
            },
            payload: eventBusPayload
        });
    };

    function handleOneTrustGroupsUpdated() {
        const interactionType =
            $window.OneTrust?.GetDomainData()?.ConsentIntegrationData?.consentPayload
                ?.dsDataElements?.InteractionType;
        if (interactionType) {
            const oneTrustEvent = ONE_TRUST_EVENTS[interactionType];
            eventBus.dispatch(oneTrustEvent, this);
            eventBus.dispatchAmplitude({
                event_type: AMPLITUDE_EVENTS.SELECT_BUTTON,
                event_properties: {
                    selection: oneTrustEvent
                }
            });
        }
    }

    $window.addEventListener("OneTrustGroupsUpdated", handleOneTrustGroupsUpdated);
    const updateCssAccu = function (val) {
        if (val) {
            $rootScope.css.accu = val;
            globalThis.css.accu = val;
        } else {
            $rootScope.css.accu = $window.accu;
            globalThis.css.accu = $window.accu;
        }
    };

    function isLoginPageValidation(route) {
        return (
            route.indexOf("login?") > -1 ||
            route.substring(route.length - 5, route.length) === "login" ||
            route.indexOf("paeSSOlanding") > -1 ||
            route.indexOf("noSession") > -1 ||
            route.indexOf("login/44401") > -1 ||
            route.indexOf("login/deepLink") > -1 ||
            (route.indexOf("login") > -1 && route.indexOf("debug=true") > -1)
        );
    }

    $scope.getBackgroundImage = function () {
        let className = "";

        switch ($location.path()) {
            case "/accountOpenDetails":
                className = "site-wrapper-ots";
                break;
            case "/accountOpenPersonalDetails":
                className = "site-wrapper-ots";
                break;
            default:
                className = "site-wrapper";
                break;
        }
        return className;
    };

    $rootScope.accuResolved = false;
    $scope.$watch(
        function () {
            return $window.accuResolved;
        },
        function (newVal) {
            if (newVal) {
                logger.debug(`accuResolved watch triggered - ["${newVal}"]`);
                $rootScope.accuResolved = newVal; // should be true
            }
        }
    );

    if (!$rootScope.ssoLogin) {
        $scope.$watch(
            () => $window.accu,
            (newVal, oldVal) => {
                if (newVal !== oldVal) {
                    updateCssAccu(newVal);
                    logger.debug(`$scope.accu watch triggered - ["${newVal}"]`);
                }
            }
        );

        AuthenticationStatusService.getAuthData().then(
            function (data) {
                function propertyToBoolean(val) {
                    const booleanState = ["false", "true"].indexOf(val);
                    return booleanState >= 0 ? Boolean(booleanState) : val;
                }

                const authenticationStatus = _mapValues(data, propertyToBoolean);
                authenticated = authenticationStatus.authenticated;

                if (authenticated) {
                    const accountService = $injector.get("accountService");

                    accountService.setAccountDetails().then(function (result) {
                        updateCssAccu(result.accuCode);
                        $rootScope.css.product = result.groupDetails.productId;
                        $rootScope.css.group = result.groupId;
                        $rootScope.css.groupClient = result.groupDetails.groupClientId;

                        $window.accu = result.accuCode;

                        globalThis.css.accu = result.accuCode;
                        globalThis.css.product = result.groupDetails.productId;
                        globalThis.css.group = result.groupId;
                        globalThis.css.groupClient = result.groupDetails.groupClientId;
                    });
                }
                // Clear duplicated cookies
                const currentUrl = $window.location.href;
                if (currentUrl.indexOf("participant") >= 0 && currentUrl.indexOf("login") >= 0) {
                    logger.debug("Try to clear cookies...");
                    const accu = AccuCodeService.getAccuCode();
                    const currentAccu = $scope.accu ? $scope.accu : accu;
                    AuthenticationStatusService.clearSpanishLanguageCookies().then(
                        function (data) {
                            logger.debug("spanish data: ", data);
                            logger.debug("Prelogin Spanish cookies have been cleared.", data);
                            AuthenticationStatusService.clearLanguageCookies().then(
                                function (resp) {
                                    logger.debug("Prelogin cookies have been cleared.", resp);

                                    $cookies.put("accu", currentAccu, { path: "/" });
                                    $cookies.put("accu", currentAccu, { path: "/participant" });
                                    $cookies.put("clientAccu", currentAccu, { path: "/" });
                                    $cookies.put("clientAccu", currentAccu, {
                                        path: "/participant"
                                    });
                                },
                                function (error) {
                                    logger.error("ERROR - clearLanguageCookies(): ", error);
                                }
                            );
                        },
                        function (error) {
                            logger.error("ERROR - clearSpanishLanguageCookies(): ", error);
                        }
                    );
                }
            },
            function (error) {
                throw Error(error);
            }
        );
    }
    function setLoginVisiblity(event) {
        const hideLoginPatterns = [
            /\/docs\/broker-check/,
            /\/docs\/business-continuity/,
            /\/docs\/excessive-trading/,
            /\/docs\/privacy/,
            /\/docs\/security/,
            /\/docs\/terms-and-conditions/
        ];

        const shouldShowLogin = false;

        const route = $location.url();

        const isDeepLink = route.startsWith("/d/");
        const setDeepLinkParameters = (deepLink) =>
            ($scope.deepLinkData.params = deepLink.includes("?") ? deepLink.split("?")[1] : null);

        $scope.newOpenAnAccount = route.indexOf("newOpenAnAccount") > -1;

        if (isDeepLink) setDeepLinkParameters(route);

        if (
            _some(hideLoginBeforeAuthPatterns, function (pattern) {
                return route.match(pattern);
            })
        ) {
            $scope.isLogin = false;
            $scope.showLogin = false;
            $rootScope.$emit("loginAuthStatusVerified", $scope.authenticationStatus);
        } else {
            $scope.isLogin = isLoginPageValidation(route);
            $scope.isMobile = isMobileDevice();

            if ($scope.isLogin) {
                $scope.showLogin = true;
                if ($scope.isMobile && $rootScope.isArticles) {
                    $scope.showBlockingView = true;
                }
            } else {
                $scope.showBlockingView = false;
            }

            AuthenticationStatusService.getAuthData().then(function (data) {
                function propertyToBoolean(val) {
                    const booleanState = ["false", "true"].indexOf(val);
                    return booleanState >= 0 ? Boolean(booleanState) : val;
                }

                const authStatus = _mapValues(data, propertyToBoolean);

                if (authStatus.authenticated) {
                    $scope.showLogin = !_some(hideLoginPatterns, function (pattern) {
                        return route.match(pattern);
                    });
                } else if (isDeepLink && $rootScope.iframeDeepLink) {
                    // iframe deep link; at this point the user has been authenticated
                    $scope.showLogin = false;
                } else {
                    $scope.showLogin = true;
                    $cookies.remove("PM-INDID-TOKEN", { path: "/" });
                    $cookies.remove("PM-PLAN-TOKEN", { path: "/" });
                    // $cookies.remove('PM-ACCU-TOKEN', {path: '/'});
                }

                if (
                    $scope.showLogin &&
                    authStatus.authenticated &&
                    route.indexOf("login/deepLink") < -1
                ) {
                    LogoutService.logOutServices.query(function () {
                        LogoutService.logOutPartAuth.query().$promise.then(
                            {},
                            function () {
                                $cookies.remove("JSESSIONID", {
                                    path: "/participant-web-services"
                                });
                                $cookies.remove("JSESSIONID", {
                                    path: "/participant-authentication"
                                });
                                $cookies.remove("site_tok", { path: "/participant" });
                                $cookies.remove("site_tok", { path: "/" });
                                $cookies.remove("X-CSRF-TOKEN", { path: "/" });
                                $cookies.remove("PM-INDID-TOKEN", { path: "/" });
                                $cookies.remove("PM-PLAN-TOKEN", { path: "/" });
                                // $cookies.remove('PM-ACCU-TOKEN', {path: '/'});
                            },
                            function () {
                                $cookies.remove("JSESSIONID", {
                                    path: "/participant-web-services"
                                });
                                $cookies.remove("JSESSIONID", {
                                    path: "/participant-authentication"
                                });
                                $cookies.remove("site_tok", { path: "/participant" });
                                $cookies.remove("site_tok", { path: "/" });
                                $cookies.remove("X-CSRF-TOKEN", { path: "/" });
                                $cookies.remove("PM-INDID-TOKEN", { path: "/" });
                                $cookies.remove("PM-PLAN-TOKEN", { path: "/" });
                                // $cookies.remove('PM-ACCU-TOKEN', {path: '/'});
                            }
                        );
                        MenuNavigationFactory.getAccu.query({}, function (code) {
                            //using image rather than $http due to redirects.
                            const tmpImg = new Image();
                            if (code[0] === $scope.accu) {
                                tmpImg.src =
                                    // eslint-disable-next-line no-undef
                                    __iframeUrl__ + "/logoutNextgen.do?accu=" + $scope.accu;
                            } else {
                                tmpImg.src =
                                    // eslint-disable-next-line no-undef
                                    __iframeUrl__ +
                                    "/logoutNextgen.do?accu=" +
                                    code[0] +
                                    "&overrideAccu=" +
                                    $scope.accu;
                            }
                            $rootScope.$emit(
                                "loginAuthStatusVerified",
                                $scope.authenticationStatus
                            );
                        });
                    });
                } else {
                    $rootScope.$emit("loginAuthStatusVerified", $scope.authenticationStatus);
                }
            });

            if (event.name !== "$stateChangeStart") {
                $timeout($scope.showLogin, $scope.getInnerContainerOffset);
            } else if (!_isUndefined($scope.showLogin)) {
                $scope.showLogin = shouldShowLogin;
            }
        }
    }

    // Scroll page to top of screen when state changes
    function scrollToTop() {
        document.body.scrollTop = document.documentElement.scrollTop = 0;
    }

    function updateHomeHref() {
        if (authenticated) {
            const tagHome = document.querySelectorAll(".breadcrumb li a");
            if (tagHome.length > 0) {
                tagHome[0].href =
                    window.location.origin + `/participant/home/#/dashboard/retirement-income`;
            }
        }
    }

    function stateChangeSuccess(e, ctx) {
        $modalStack.dismissAll(); //close any modals

        setLoginVisiblity.apply(null, arguments);
        if (!$rootScope.ssoLogin) {
            scrollToTop();
        }
        if ($scope.isMobile) {
            if (ctx.name === "dynamicArticle") {
                $rootScope.isArticles = true;
                $scope.showBlockingView = false;
                // change href value
                $timeout(function () {
                    // This code runs after the DOM renders
                    updateHomeHref();
                }, 2000);
            } else {
                $rootScope.isArticles = false;
                $scope.showBlockingView = true;
            }
        } else {
            $scope.showBlockingView = false;
        }
    }

    $scope.$on("$stateChangeSuccess", stateChangeSuccess);

    $scope.getInnerContainerOffset = function () {
        const $container = document.querySelector(".inner-container");
        if ($container) {
            $scope.containerTop = $container.offsetTop;
        } else {
            $scope.containerTop = 0;
        }
        return $scope.containerTop;
    };

    if (!$rootScope.ssoLogin) {
        pscPathRetrievalFactory.pscRetrievalPath.query(function (data) {
            $scope.urls.pscPath = data[0];
        });

        const accuParam = AccuCodeService.getAccuCode();
        $scope.accu = accuParam;

        logger.info("accu is [{0}]", [accuParam]);

        SetACCUService.query({ accu: accuParam }).$promise.then(function () {
            logger.info("setAccu call completed...[{0}]", [accuParam]);
        });

        DefaultCustomizationRetrService.query().$promise.then(function (defaultData) {
            PreLoginAccuRetrievalService.query({ accu: $scope.accu }).$promise.then(
                function (preLoginData) {
                    sessionStorage.setItem("accuFaviconPng", preLoginData.faviconPng);
                    $scope.accuCustomization = angular.extend({}, defaultData, preLoginData);
                    $rootScope.accuCustomization = angular.extend({}, defaultData, preLoginData);
                    globalThis.accuCustomization = { ...defaultData, ...preLoginData };
                    const event = new CustomEvent("accu:updated");
                    document.dispatchEvent(event);

                    $rootScope.accuSpecificPrimDb =
                        $scope.accuCustomization.primaryDatabase.preLoginPrimaryDB;
                    PreLoginBulletinService.query(
                        {
                            accu: $scope.accu,
                            primaryDBFlag: $rootScope.accuSpecificPrimDb
                        },
                        function (data) {
                            $scope.bulletins = data;

                            setTimeout(function () {
                                const bulletinModal = document.querySelector(".modal-content");

                                if (bulletinModal) {
                                    bulletinModal.focus();
                                    bulletinModal.addEventListener("keydown", function (e) {
                                        const isTabPressed = e.key === "Tab" || e.keyCode === 9;

                                        if (isTabPressed) {
                                            bulletinModal.focus();
                                        }
                                    });
                                }
                            }, 100);
                        }
                    );
                }
            );
        });
    }

    $scope.getPreLoginBodyTemplate = function getPreLoginBodyTemplate() {
        return __styleEnv__ + $scope.accuCustomization.preLoginBodyTemplate || "";
    };

    $scope.getMobileBlockingTemplate = function getMobileBlockingTemplate() {
        return __styleEnv__ + $scope.accuCustomization.blockingMobileTemplate || "";
    };

    $scope.resetAuthForm = function () {
        logger.info("resetting auth form");
        $scope.authenticationStatus = {};
        $scope.loggingIn = false;
        if ($scope.loggingInTimer !== undefined && $scope.loggingInTimer !== null) {
            $timeout.cancel($scope.loggingInTimer);
        }
        $rootScope.showNonAuthHeader = {
            value: true
        }; //set strictly to true in LoginUI
        $scope.$emit("authenticationStatusUpdated", $scope.authenticationStatus);
    };

    /**
     * This method is called when the user clicks the 'Sign In' button on the oauth login form.
     * Due to change in method signature, this method is now a wrapper around the new 'authenticate' method.
     * When we convert to pure react, call method, authenticate directly from the button click event and
     * remove this method.
     */
    $scope.oauthAuthenticate = function () {
        const username = $scope.credentials.username;
        const password = $scope.credentials.password;

        $scope.authenticate({ username, password });
    };

    $scope.authenticate = function ({ username, password, enableLoginHelp }) {
        eventBus.dispatch(CoreEvents.AUTHENTICATE, this);
        eventBus.dispatchAmplitude({
            event_type: AMPLITUDE_EVENTS.SELECT_BUTTON,
            event_properties: {
                selection: CoreEvents.AUTHENTICATE
            }
        });
        $scope.credentials.username = username;
        $scope.credentials.password = password;
        $scope.enableLoginHelp = enableLoginHelp;

        $scope.authentication.processing = "";
        $scope.authentication.errorMessage = "";
        $scope.errorCode = $scope.authentication.errorMessage;
        $scope.authentication.errorMessageParams = {};
        $scope.errorMessageParams = $scope.authentication.errorMessageParams;
        $scope.loggingIn = true;
        $scope.loggingInTimer = $timeout(function () {
            $scope.loggingIn = false;
        }, 60000);

        if (
            $scope.credentials === undefined ||
            $scope.credentials.username === undefined ||
            $scope.credentials.password === undefined ||
            $scope.credentials.username === "" ||
            $scope.credentials.password === ""
        ) {
            eventBus.dispatch(CoreEvents.INPUT_ERROR, this);
            eventBus.dispatchAmplitude({
                event_type: AMPLITUDE_EVENTS.SELECT_FIELD,
                event_properties: {
                    selection: CoreEvents.INPUT_ERROR
                }
            });
            $scope.authentication.errorMessage = "missingInput";
            $scope.errorCode = "missingInput";
            $scope.authentication.processing = "failed";

            $scope.loggingIn = false;
            handleScreenReader();
        } else {
            $scope.authentication.processing = "pending";
            FingerprintUtils.getFingerprint(false).then((fp) => {
                const path = $location.path();

                let flowName = "mfa";
                if (path.indexOf("deepLink") !== -1) {
                    flowName = "deepLink";
                }

                $scope.idProofEnabled = $translate.instant("idProofing.enabled");

                fp.userName = $scope.credentials.username;
                fp.password = $scope.credentials.password;
                fp.flowName = flowName;
                fp.accu = $scope.accu;
                if ($rootScope.gaId) {
                    fp.flowNameGaId = $rootScope.gaId;
                }

                if (!path.includes("/loginPart") && !path.includes("/loginDTW")) {
                    BasicAuthenticationService.setidProof($scope.idProofEnabled);
                }

                setTimeout(function () {
                    $scope.isLoginPart = "";
                    const hasLoginPart =
                        String(window.location.href).indexOf("isLoginPart=true") > -1;
                    //if we have special url/flag set in ui-style-guide as loginPart call authenticatePart else go to existing authenticate/authenticateLite flow.
                    if (
                        (path.includes("/loginPart") && $scope.accuCustomization.isLoginPart) ||
                        hasLoginPart
                    ) {
                        $scope.isLoginPart = true;
                        $scope.setPcBaseApiUrl = multiAuth[$window.subdomain];
                        $scope.authPartPayload = "";
                        $scope.authPartPayload = fp;
                        AuthenticationService.authenticatePart(
                            $scope.setPcBaseApiUrl,
                            fp
                        ).$promise.then(onAuthenticateSuccess, onAuthenticateFailure);
                    } else {
                        AuthenticationService.authenticateLite
                            .query(fp)
                            .$promise.then(onAuthenticateSuccess, onAuthenticateFailure);
                    }
                }, 100);
            });
        } //End of auth function
    };

    // focus the error messages
    const handleScreenReader = function () {
        AccessibilityUtil.focusElement("screenReader");
    };

    /**
     * This method will return the accu code associated with an 'empower restricted plan' if any such plan is returned for the ppt.
     */
    const getEmpowerRestrictedPlanAccuCode = function (data) {
        const method = "getEmpowerRestictedPlanAccuCode";

        const enforceEmpowerRestrictedPlans = $translate.instant("enforceEmpowerRestrictedPlans");
        logger.debug("{0} - enforceEmpowerRestrictedPlans=[{1}]", [
            method,
            enforceEmpowerRestrictedPlans
        ]);

        if (enforceEmpowerRestrictedPlans !== "true") {
            return null;
        }

        if (!data.accessPortalGroups || data.accessPortalGroups.length === 0 || !data.primaryPlan) {
            return null;
        }
        const accessPortalGroups = data.accessPortalGroups;
        const primaryPlan = data.primaryPlan;
        if (primaryPlan.gaId) {
            $rootScope.gaId = primaryPlan.gaId;
        }

        logger.debug("{0} - search primaryPlan : {1} over accessPortalGroups : {2}", [
            method,
            primaryPlan ? JSON.stringify(primaryPlan) : "null",
            accessPortalGroups ? JSON.stringify(accessPortalGroups) : "null"
        ]);

        for (let i = 0; i < accessPortalGroups.length; i++) {
            const accessPortalGroup = accessPortalGroups[i];
            if (accessPortalGroup.accessPortalList) {
                for (let j = 0; j < accessPortalGroup.accessPortalList.length; j++) {
                    const accessPortal = accessPortalGroup.accessPortalList[j];
                    logger.debug("{0} - evaluating accessPortal: {1}", [
                        method,
                        JSON.stringify(accessPortal)
                    ]);

                    //look for a match of primary plan with accessPortalList and return the accu code associated.
                    if (
                        primaryPlan.gaId === accessPortal.gaId &&
                        primaryPlan.indId === accessPortal.indId
                    ) {
                        logger.debug("{0} - found restricted plan. returning {1}. ", [
                            method,
                            accessPortalGroup.accu
                        ]);
                        return accessPortalGroup.accu;
                    }
                }
            }
        }

        logger.debug("{0} - returning null", [method]);
        return null;
    };

    const onAuthenticateSuccess = async function (data) {
        const method = "onAuthenticateSuccess";

        eventBus.dispatch(CoreEvents.AUTH_SUCCESS, this);
        eventBus.dispatchAmplitude({
            event_type: AMPLITUDE_EVENTS.SELECT_FIELD,
            event_properties: {
                selection: CoreEvents.AUTH_SUCCESS
            }
        });

        $scope.authentication.processing = "loginsuccess";
        BasicAuthenticationService.clearCredentials();
        $scope.credentials.username = "";
        $scope.credentials.password = "";

        // Place userId into root scope
        $scope.authentication.userId = data.userRegId;

        $scope.authentication.flowName = "mfa";
        $rootScope.flowName = "mfa";
        $scope.authentication.errorMessage = "";

        if ($scope.isLoginPart) {
            if (data.authProvider == "REDWOOD") {
                const redwoodResponse = await redwoodAuthenticateToken(
                    $scope.setPcBaseApiUrl,
                    data.idToken
                );
                loadFtd(redwoodResponse);
            } else {
                try {
                    $scope.authPartPayload.idToken = data.idToken;
                    delete $scope.authPartPayload["userName"];
                    delete $scope.authPartPayload["password"];
                    data = await StateDestinationFactory.authenticateToken($scope.authPartPayload);
                } catch (error) {
                    logger.error("ERROR - authenticateToken api: ", error);
                }
            }
        }

        if (data.state === null || data.state === "") {
            StateDestinationFactory.getParticipantDataAsync();
            StateDestinationFactory.logBrowserInfo();
            try {
                data = await StateDestinationFactory.routeDetermination();
            } catch (error) {
                logger.error("ERROR - routeDetermination api: ", error);
            }
        } else if (data.state === "ACTIVATION_CODE_INFO") {
            StateDestinationFactory.getParticipantDataAsync();
            StateDestinationFactory.logBrowserInfo();
            StateDestinationFactory.routeDetermination();
        }

        if (data.state === "emptyDeliveryOptions") {
            $scope.emptyDeliveryOptionsMessage = $translate("emptyDeliveryOptionsMessage");
        } else {
            $scope.authentication.errorMessage = "";
        }

        if ($scope.accu.toLowerCase() === "empower") {
            const newAccuCode = getEmpowerRestrictedPlanAccuCode(data);

            if (
                newAccuCode &&
                $window.accu !== newAccuCode &&
                $window.supportedACCU.folders.indexOf(newAccuCode) >= 0
            ) {
                logger.debug("{0} - Empower Restricted authentication..changing accu to [{1}]", [
                    method,
                    newAccuCode
                ]);

                $window.accu = newAccuCode;
                globalThis.css.accu = newAccuCode;

                //update tomcat
                logger.debug("{0} - updating accu...", [method]);

                let contactData;
                let preLoginData;
                let defaultData;
                let resetAccuData;

                try {
                    resetAccuData = await ReSetACCUService.query({ accu: $window.accu }).$promise;
                    //set cookie
                    $cookies.remove("accu", { path: "/" });
                    $cookies.put("accu", $window.accu, { path: "/" });
                    $cookies.remove("accu", { path: "/participant" });
                    $cookies.put("accu", $window.accu, { path: "/participant" });
                    $cookies.remove("clientAccu", { path: "/" });
                    $cookies.put("clientAccu", $window.accu, { path: "/" });
                    $cookies.remove("clientAccu", { path: "/participant" });
                    $cookies.put("clientAccu", $window.accu, {
                        path: "/participant"
                    });
                    // added to resolve SR # 03597037   - cookie used for legacy pptweb quicken download feature
                    $cookies.remove("PM-ACCU-TOKEN", { path: "/" });
                    $scope.accu = AccuCodeService.getAccuCode();
                    updateCssAccu($scope.accu);
                    // to reload customizations...

                    logger.debug(method + "- re-loading default customizations..", method);
                } catch (error) {
                    logger.error("ERROR - ReSetACCUService.query(): ", error);
                    $rootScope.$emit("reset-loading-button-submit"); // <-- not working... hmm.
                }

                if (resetAccuData) {
                    try {
                        defaultData = await DefaultCustomizationRetrService.query().$promise;
                        logger.debug(method + "- default customizations..", method, defaultData);
                        logger.debug(method + "- re-loading preLogin accu data..", method);
                    } catch (error) {
                        logger.error("ERROR - DefaultCustomizationRetrService.query(): ", error);
                    }
                }

                if (defaultData) {
                    try {
                        preLoginData = await PreLoginAccuRetrievalService.query({
                            accu: $scope.accu
                        }).$promise;
                        logger.debug(method + "- preLogin accu data..", method, preLoginData);

                        $scope.accuCustomization = angular.extend({}, defaultData, preLoginData);
                        globalThis.accuCustomization = { ...defaultData, ...preLoginData };
                        $rootScope.accuSpecificPrimDb =
                            $scope.accuCustomization.primaryDatabase.preLoginPrimaryDB;

                        logger.debug(method + "- re-loading PreLogin contact info..", method);
                        logger.debug(
                            method + "- existing siteContactInfo",
                            $rootScope.siteContactInfo
                        );
                    } catch (error) {
                        logger.error("ERROR - PreLoginAccuRetrievalService.query(): ", error);
                    }
                }

                if (preLoginData) {
                    try {
                        contactData = await PreLoginContactInfoService.query({
                            accu: $scope.accu,
                            accuSpecificPrimDb: $rootScope.accuSpecificPrimDb,
                            portalType: "TOLLFREE"
                        }).$promise;
                        $rootScope.siteContactInfo = contactData;

                        logger.debug(
                            method + "-  NEW PreLogin contact info..",
                            method,
                            contactData
                        );

                        $rootScope.$broadcast("siteContactInfoUpdated", $rootScope.siteContactInfo);
                        $rootScope.$broadcast(
                            "loginAuthStatusVerified",
                            $scope.authenticationStatus
                        );
                        $rootScope.$broadcast("customizationUpdated", $scope.accuCustomization);

                        $cookies.remove("accu", { path: "/" });
                        $cookies.put("accu", $scope.accu, { path: "/" });
                        $cookies.remove("accu", { path: "/participant" });
                        $cookies.put("accu", $scope.accu, { path: "/participant" });
                        $cookies.remove("clientAccu", { path: "/" });
                        $cookies.put("clientAccu", $scope.accu, { path: "/" });
                        $cookies.remove("clientAccu", { path: "/participant" });
                        $cookies.put("clientAccu", $scope.accu, { path: "/participant" });

                        logger.debug("{0} - Accu code reset to: [{1}]", [method, $scope.accu]);

                        redirectService.redirect(data, "ALL", "loginMasterController");
                    } catch (error) {
                        logger.error("ERROR - PreLoginContactInfoService.query(): ", error);
                    }
                }
            } else {
                redirectService.redirect(data, "ALL", "loginMasterController");
            }
        } else {
            redirectService.redirect(data, "ALL", "loginMasterController");
        }
        // After successful authentication, toggle logginIn loading flag back to false
        $scope.loggingIn = false;
    };
    const onAuthenticateFailure = function (error) {
        eventBus.dispatch(CoreEvents.AUTH_FAIL, this);
        eventBus.dispatchAmplitude({
            event_type: AMPLITUDE_EVENTS.SELECT_FIELD,
            event_properties: {
                selection: CoreEvents.AUTH_FAIL
            }
        });
        $scope.credentials.password = "";
        $scope.authentication.errorMessage = "";
        $scope.errorCode = $scope.authentication.errorMessage;
        $scope.authentication.processing = "failed";

        if (error.data.status == "FAIL") {
            $scope.authentication.errorMessage = error.data.error.code;
            $scope.errorCode = error.data.error.code;

            if (error.data.error.errors !== null) {
                $scope.authentication.errorMessageParams = error.data.error.errors[0];
                $scope.errorMessageParams = $scope.authentication.errorMessageParams;

                if ($scope.authentication.errorMessageParams.code === "logon.accu.hardstop") {
                    $scope.loginurl = $scope.authentication.errorMessageParams.errorCode;
                    redirectService.setLoginURL($scope.loginurl, "Login Error", "login");
                    $state.go("registrationHardStopError");
                } else if (
                    $scope.authentication.errorMessageParams.code === "error.passcode.incorrect" &&
                    $scope.authentication.errorMessageParams.attempts === "1"
                ) {
                    let redirectUrl = "participant/#/loginHelp?errorMessage=true&attempts=done";
                    if (String($window.location.href).indexOf("optimizelyEndUserId") > -1) {
                        // handle no scope on optimzelyEndUserId
                        redirectUrl = `participant/#/loginHelp?errorMessage=true&attempts=done&noScope=true&count=${$scope.authentication.errorMessageParams.attempts}`;
                    }

                    let shouldShowNewLoginHelp = false;
                    if (
                        $scope.enableLoginHelp === "Y" ||
                        String(window.location.href).indexOf("localhost") > -1 ||
                        String(window.location.href).indexOf("proj10") > -1
                    ) {
                        shouldShowNewLoginHelp = true;
                    }

                    if (
                        (String($scope.accu).toLowerCase() === "empower" ||
                            String($scope.accu).toLowerCase() === "empowerstaff" ||
                            String($scope.accu).toLowerCase() === "myerira") &&
                        shouldShowNewLoginHelp
                    ) {
                        // redirect to loginHelpOptions page
                        redirectUrl = "participant/#/loginHelpOptions?&accu=" + $scope.accu;
                        if (String($window.location.href).indexOf("optimizelyEndUserId") > -1) {
                            redirectUrl = `participant/#/loginHelpOptions?&accu=${$scope.accu}&errorMessage=true&attempts=done&noScope=true&count=${$scope.authentication.errorMessageParams.attempts}`;
                        }
                    }

                    $rootScope.errorMessage = {
                        code: "error.passcode.incorrect.redirect.help",
                        attempts: $scope.authentication.errorMessageParams.attempts
                    };
                    const redirectData = {
                        destinationUrl: redirectUrl,
                        from: "LOGIN_ERROR"
                    };
                    $scope.showLogin = false;
                    redirectService.redirect(redirectData, "ALL", "loginMasterController");
                    deferred.resolve();
                } else if ($scope.authentication.errorMessage === "alternative.error.login") {
                    $scope.accuError = {
                        showDialog: true,
                        redirectAccuUrl: sanitizeURLforRedirection(
                            $scope.authentication.errorMessageParams.ALTERNATIVE_LOGN_URL
                        )
                    };

                    $scope.redirectAccuSite();
                }
            }
            $scope.loggingIn = false;
        }
        handleScreenReader();
        deferred.reject(error);
    };

    $scope.activateTab = function (event) {
        const navs = Array.prototype.slice.call(document.querySelectorAll(".register-tab"), 0);
        navs.forEach((el) => {
            el.classList.remove("active");
        });
        const targLink = event.currentTarget;
        targLink.parentElement.classList.add("active");
        const clickEvent = document.createEvent("MouseEvents");
        clickEvent.initEvent("dblclick", true, true);
        targLink.dispatchEvent(clickEvent);
    };

    $scope.registerButtonClick = function () {
        $scope.showLogin = false;
    };

    $scope.openAccountClick = function (event) {
        event.preventDefault();
        $scope.showLogin = false;
        window.location.href = "#/articles/MYERIRA/openAnAccount";
    };
    $scope.openPremierAccount = function () {
        eventBus.dispatch(AccountOpenEvents.CLICK_PREMIER_IRA);
    };

    $scope.redirectAccuSite = function () {
        $scope.dialogAccuErrorTimer = $timeout(function () {
            $scope.accuError.showDialog = false;
            window.location.replace($scope.accuError.redirectAccuUrl);
        }, 30000);
    };

    function sanitizeURLforRedirection(url) {
        //pattern
        //if the url comes like www.viacomwealth.com then is going to be updated as http://www.viacomwealth.com
        //if the url comes like bbmretirementplan.com then is going to be updated as http://bbmretirementplan.com
        const patternProtocol = new RegExp(/http(s)?:\/\//);
        url = url.trim();
        if (!patternProtocol.test(url)) {
            url = "http://" + url;
        }
        return url;
    }

    $window.goToState = function (inStateName, inParams) {
        logger.debug(
            "goToState() [{0}] : {1}",
            inStateName,
            inParams ? JSON.stringify(inParams) : "null"
        );
        if (inStateName) {
            $state.go(inStateName, inParams ? inParams : {});
        }
    };

    DefaultCustomizationRetrService.query().$promise.then(
        function () {
            PreLoginAccuRetrievalService.query({ accu: $scope.accu }).$promise.then(
                function (preLoginData) {
                    sessionStorage.setItem("accuFaviconPng", preLoginData.faviconPng);
                    // eslint-disable-next-line no-prototype-builtins
                    if (preLoginData && preLoginData.hasOwnProperty("blockingMobileDevices")) {
                        const overrideBlockingMobileDevices = () => {
                            const searchParams = getSearchParams($window.location);

                            if (hasBlockingParam(searchParams)) {
                                forceBlockingMobileDevices(getBlockingParamValue(searchParams));
                            }
                        };

                        const getSearchParams = (url) =>
                            url.hash.includes("?") ? url.hash.split("?")[1] : null;

                        const hasBlockingParam = (params) => params && params.includes("blocking");

                        const getBlockingParamValue = (params) =>
                            params.match(/blocking=\w+/)[0].split("=")[1];

                        const forceBlockingMobileDevices = (value) => {
                            preLoginData.blockingMobileDevices = value === "true";
                        };

                        overrideBlockingMobileDevices();

                        $scope.blockingMobileDevices = preLoginData.blockingMobileDevices;
                        $scope.isMobile = isMobileDevice();

                        if ($scope.blockingMobileDevices && $scope.isMobile && !$scope.isOauth) {
                            const isArticle = $location.path().search("articles") > 0;
                            const isInvestmentInformation =
                                $location.path().search("investmentInformation") > 0;
                            $scope.showBlockingView = !(isArticle || isInvestmentInformation);
                            $scope.showLogin = false;
                        } else {
                            $scope.blockingMobileDevices = false;
                            $scope.isMobile = false;
                            $scope.showBlockingView = false;

                            //  $scope.showLogin = true;
                        }
                    } else {
                        $scope.blockingMobileDevices = false;
                        $scope.isMobile = false;
                        $scope.showBlockingView = false;
                    }
                    $scope.isBlockingLoaded = true;
                },
                function (_err) {
                    logger.error("ERROR: ", _err);
                    $scope.isBlockingLoaded = true;
                }
            );
        },
        function (_err) {
            logger.error("ERROR: ", _err);
            $scope.isBlockingLoaded = true;
        }
    );
};

LoginUIMasterController.$inject = [
    "$cookies",
    "$http",
    "$injector",
    "$location",
    "$modalStack",
    "$q",
    "$rootScope",
    "$scope",
    "$state",
    "$timeout",
    "$translate",
    "$window",
    "AccuCodeService",
    "AuthenticationService",
    "AuthenticationStatusService",
    "BasicAuthenticationService",
    "DefaultCustomizationRetrService",
    "LogoutService",
    "MenuNavigationFactory",
    "PreLoginAccuRetrievalService",
    "ReSetACCUService",
    "PreLoginContactInfoService",
    "PreLoginBulletinService",
    "SetACCUService",
    "StateDestinationFactory", // weird...needs $injector
    "eventBus",
    "redirectService", // bad?
    "pscPathRetrievalFactory"
];
export default LoginUIMasterController;
